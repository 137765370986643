import axios from 'axios';
import _get from 'lodash/get';
// import Router from 'next/router';
import cookie from 'js-cookie';
import Router from 'next/router';
import * as Sentry from '@sentry/nextjs';

export default function request(url, option, withToken = true) {
  const options = {
    ...option,
  };
  const defaultOptions = {
    headers: {
      'X-Api-Client-Id': '2cfc1c88-f2be-48f1-abd4-dde2e5541e9d',
      'X-Api-Public-Key': 'N58UiJ2b7kg1q384i8xH3IfQJoVfZFFvaf1uM74xcIvKqk5NfsO4bWhUQK9WiaGM',
    },
  };
  let token = cookie.get('sol_cust') || '{}';
  if (withToken && token) {
    token = JSON.parse(token);
    /* eslint-disable-next-line */
    const { token_type, access_token } = token;
    /* eslint-disable-next-line */
    if (token_type && access_token) {
      defaultOptions.headers = {
        ...defaultOptions.headers,
        Authorization: `${token_type} ${access_token}` /* eslint-disable-line */,
      };
    }
  }
  const newOptions = { ...defaultOptions, ...options };
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'content-type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        'content-yype': 'multipart/form-data',
        ...newOptions.headers,
      };
    }
  }

  let client = axios.create({ ...newOptions });

  if (newOptions.method == 'GET') {
    client = client.get(`${url}`, { ...newOptions.body });
  }

  if (newOptions.method == 'POST') {
    if (!(newOptions.body instanceof FormData)) {
      client = client.post(`${url}`, { ...newOptions.body });
    } else {
      client = client.post(`${url}`, newOptions.body);
    }
  }

  if (newOptions.method == 'PUT') {
    client = client.put(`${url}`, { ...newOptions.body });
  }

  return client
    .then(res => res)
    .catch(err => {
      const status = _get(err, 'response.status');
      const data = _get(err, 'response.data');
      if (status === 401) {
        let redirect_url = '';
        const locale = cookie.get('language');
        const canRedirectIf401 = _get(options, 'body.canRedirectIf401', true);
        cookie.remove('sol_cust', {
          path: '/',
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        });
        if (canRedirectIf401) {
          if (typeof window !== undefined) {
            const cartOrderId = _get(options, 'body.order_id');
            if (cartOrderId) {
              redirect_url = encodeURI(
                locale && locale !== 'en'
                  ? `${process.env.NEXT_PUBLIC_DOMAIN_URL}/${locale}/cart?id=${cartOrderId}`
                  : `${process.env.NEXT_PUBLIC_DOMAIN_URL}/cart?id=${cartOrderId}`
              );
            } else {
              redirect_url = encodeURI(_get(window, 'location.href'));
            }
          }
          Router.push(`/permission?redirect_url=${redirect_url}`);
        }
      }
      // environment should not be used
      if (status === 403) {
        // Router.push('/exception/403');
        return;
      }
      if (status <= 504 && status >= 500) {
        // Router.push('/exception/500');
        Sentry.captureException(err, {
          extra: newOptions,
        });
        return data;
      }
      if (status >= 404 && status < 422) {
        // Router.push('/exception/404');
      }
      return data;
    });
}

function getUrlVars() {
  const vars = {};
  // eslint-disable-next-line
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
    vars[key] = value;
  });
  return vars;
}

export function getUrlParam(parameter, defaultvalue) {
  let urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}
