import { observable } from 'mobx';
class GlobalStore {
  // lang resource
  translations = {};
  // language
  @observable lang = '';

  // i18n instance
  async init(lang) {
    this.lang = lang;
  }
}

const globalStore = new GlobalStore();
export default globalStore;
