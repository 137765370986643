import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
});

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#076BD1',
    },
    secondary: {
      main: '#C60023',
    },
    highlight: {
      main: '#27D6E3',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F6F9FC',
    },
    text: {
      primary: '#31415E',
    },
  },
  ticketSession: {
    available: '#6BDB31',
    sellingFast: '#FFDA24',
    notAvailable: '#EF211A',
    lateNight: '#A738AF',
  },
  typography: {
    h1: {
      fontSize: 20,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 24,
      },
    },
    h2: {
      fontSize: 18,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    h3: {
      fontSize: 16,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 18,
      },
    },
    h4: {
      fontSize: 14,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
    h5: {
      fontSize: 14,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
    h6: {
      fontSize: 14,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
    body1: {
      fontSize: 14,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
    caption: {
      fontSize: 12,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 12,
      },
    },
    fontSize: 14,
    fontFamily: [
      '"CircularStd-Medium"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  overrides: {
    MuiSnackbar: {
      anchorOriginTopRight: {
        top: 56,
        [defaultTheme.breakpoints.up('sm')]: {
          top: 56,
        },
      },
    },
    MuiSelect: {
      root: {
        border: `1px solid #EBEDF2`,
        padding: '10px 20px',
      },
      icon: {
        top: 4,
        right: 10,
        color: '#B1BACB',
        [defaultTheme.breakpoints.up('md')]: {
          top: 8,
        },
      },
      select: {
        '&:focus': {
          outline: 'none',
          backgroundColor: '#fff',
        },
      },
    },
    MuiTab: {
      textColorInherit: {
        opacity: '1',
        '&$selected': {
          color: '#31415E',
        },
      },
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          color: '#00AF75',
        },
        '&$completed': {
          color: '#00AF75',
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:last-child .MuiTableCell-body': {
          borderBottom: 0,
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 14,
        verticalAlign: 'top',
        paddingLeft: 0,
        paddingRight: 0,
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 14,
        },
      },
      head: {
        fontSize: 14,
        lineHeight: '14px',
        fontFamily: [
          '"CircularStd-Medium"',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        padding: '16px 10px',
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 16,
          lineHeight: '18px',
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: [
          '"CircularStd-Medium"',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 16,
        textTransform: 'capitalize',
        minWidth: 125,
        padding: '8.5px 25px',
        borderRadius: 8,
        [defaultTheme.breakpoints.up('md')]: {
          minWidth: 160,
        },
      },
      label: {
        whiteSpace: 'nowrap',
      },
      outlined: {
        fontFamily: [
          '"CircularStd-Medium"',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        border: '1px solid #fff',
      },
      outlinedPrimary: {
        color: '#fff',
      },
      contained: {
        '&.Mui-disabled': {
          color: '#ccc',
          border: '1px solid #ccc',
        },
      },
      containedSecondary: {
        color: '#808EAA',
        backgroundColor: '#F6F9FC',
        '&:hover': {
          color: '#808EAA',
          backgroundColor: '#F6F9FC !important',
        },
      },
    },
    MuiExpansionPanel: {
      rounded: {
        borderRadius: 4,
      },
      root: {
        padding: '0 20px',
        backgroundColor: 'rgba(36, 50, 64, 0.4)',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '20px 0',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
      },
    },
    MuiCollapse: {
      entered: {
        borderTop: '1px solid #DDE0E8',
      },
    },
    MuiAccordion: {
      root: {
        '&.Mui-expanded': {
          padding: '0 22px',
          [defaultTheme.breakpoints.up('md')]: {
            padding: '0 31px',
          },
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        minHeight: 80,
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '23px 0',
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${defaultTheme.spacing(3)}px 20px`,
        [defaultTheme.breakpoints.up('md')]: {
          padding: `14px 29px`,
        },
      },
    },
    PrivateRadioButtonIcon: {
      root: {
        '@global': {
          '> svg': {
            width: 18,
            [defaultTheme.breakpoints.up('md')]: {
              width: 20,
            },
          },
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0px 9px 0px 0',
        [defaultTheme.breakpoints.up('md')]: {
          padding: '0px 9px 0px 0',
        },
      },
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: 834,
      },
    },
    MuiDivider: {
      root: {
        marginBottom: defaultTheme.spacing(2),
        marginTop: defaultTheme.spacing(2),
      },
    },
    MuiMenuItem: {
      root: {
        minWidth: 200,
        fontSize: 12,
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 14,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginBottom: 16,
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    MuiFormLabel: {
      root: {
        whiteSpace: 'nowrap',
        '&$focused': {
          color: '#fff',
        },
      },
    },
    MuiInput: {
      input: {
        color: '#31415E',
        padding: '3.5px 11px 5.5px 11px',
        maxWidth: 358,
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: '#076BD1',
        },
      },
      shrink: {
        transform: 'translate(0, -1.5px) scale(1)',
        '& + .MuiInput-formControl': {
          marginTop: 13,
        },
      },
      formControl: {
        transform: 'translate(0, 10px) scale(1)',
      },
    },
    MuiInputBase: {
      input: {
        height: '1.3em',
        fontSize: 14,
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        overflow: 'hidden',
        borderRadius: 8,
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: 'rgba(0, 0, 0, 0.23) !important',
        },
      },
    },
  },
});

export default theme;
