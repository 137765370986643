import cookie from 'js-cookie';
import _get from 'lodash/get';

export const isLoggedIn = () => {
  if (typeof window !== 'undefined' && cookie.get('sol_cust')) {
    return true;
  }
  return false;
};

export const saveCustomerProfileToCookie = data => {
  const sol_cust = {
    name: _get(data, 'name'),
    email: _get(data, 'email'),
    token_type: _get(data, 'token_type'),
    address: _get(data, 'address'),
    access_token: _get(data, 'access_token'),
  };
  cookie.set('sol_cust', JSON.stringify(sol_cust), {
    expires: 1,
    path: '/',
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    secure: true,
  });
};
