import { action, observable, toJS } from 'mobx';
import request from '@utils/request';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import cookie from 'js-cookie';

class OrderStore {
  @observable loading = false;
  @observable loadingOrder = false;
  @observable order = {};
  @observable productSuggestions = [];
  @observable orders = {};
  @observable tempOrder = {
    order_items: [],
    subtotal: 0,
  };

  async init() {
    if (sessionStorage.getItem('tempOrder') !== null) {
      this.tempOrder = JSON.parse(sessionStorage.getItem('tempOrder'));
    }
  }

  calculateSubTotal = (order_items = []) => {
    let subTotal = 0;
    order_items.map(item => {
      subTotal += item.quantity * item.price;
    });
    return subTotal;
  };

  @action
  addToTempOrder(item) {
    const isFoundIndex = _findIndex(this.tempOrder['order_items'], {
      product_detail_id: _get(item, 'product_detail_id'),
    });
    if (isFoundIndex > -1) {
      const product_type = this.tempOrder['order_items'][isFoundIndex]['quantity']['product_type'];
      if (product_type === 'bundle' || product_type === 'group') {
        this.tempOrder['order_items'][isFoundIndex]['quantity'] =
          this.tempOrder['order_items'][isFoundIndex]['quantity'] + _get(item, 'quantity');
      } else {
        this.tempOrder['order_items'] = [
          ...this.tempOrder['order_items'],
          {
            ...item,
          },
        ];
      }
    } else {
      this.tempOrder['order_items'] = [
        ...this.tempOrder['order_items'],
        {
          ...item,
        },
      ];
    }
    this.tempOrder['subtotal'] = this.calculateSubTotal(this.tempOrder['order_items']);
    sessionStorage.setItem('tempOrder', JSON.stringify(this.tempOrder));
    return this.tempOrder;
  }

  @action
  updateOrderItems(orderItems) {
    this.tempOrder['order_items'] = orderItems;
    this.tempOrder['subtotal'] = this.calculateSubTotal(orderItems);
    sessionStorage.setItem('tempOrder', JSON.stringify(this.tempOrder));
    return this.tempOrder;
  }

  @action
  getCart({ subdomain }) {
    this.loadingOrder = true;
    const orderId = cookie.get('order_id') || '';
    let url = `${process.env.NEXT_PUBLIC_API_SERVER}/store/${subdomain}/get-cart`;
    if (orderId) {
      url += `?orderId=${orderId}`;
    }
    return request(url, {
      method: 'GET',
    }).then(res => {
      this.order = _get(res, 'data.data', {});
      this.loadingOrder = false;
      if (sessionStorage.getItem('tempOrder')) {
        sessionStorage.removeItem('tempOrder');
      }
      if (_get(this.order, 'id')) {
        cookie.set('order_id', _get(this.order, 'id'), {
          path: '/',
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        });
      }
      return res;
    });
  }

  @action
  addToCart({ subdomain, order_items }) {
    this.loadingOrder = true;
    const orderId = cookie.get('order_id') || '';
    let url = `${process.env.NEXT_PUBLIC_API_SERVER}/store/${subdomain}/add-to-cart`;
    if (orderId) {
      url += `?orderId=${orderId}`;
    }
    return request(url, {
      method: 'POST',
      body: {
        order_items,
      },
    }).then(res => {
      this.order = _get(res, 'data.data', {});
      if (_get(this.order, 'id')) {
        cookie.set('order_id', _get(this.order, 'id'), {
          path: '/',
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        });
      }
      this.loadingOrder = false;
      return res;
    });
  }

  @action
  verifyOrder({ order_id = '' }) {
    this.loadingOrder = true;
    return request(`${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/verify/${order_id}`, {
      method: 'GET',
    })
      .then(res => {
        this.loadingOrder = false;
        return res;
      })
      .catch(err => {
        this.loadingOrder = false;
        console.error(err);
      });
  }

  @action
  addTempToCart({ subdomain }) {
    this.loadingOrder = true;
    const tempOrder = JSON.parse(sessionStorage.getItem('tempOrder'));
    return request(`${process.env.NEXT_PUBLIC_API_SERVER}/store/${subdomain}/add-to-cart`, {
      method: 'POST',
      body: {
        order_items: _get(tempOrder, 'order_items', []),
      },
    }).then(res => {
      this.order = _get(res, 'data.data', {});
      this.loadingOrder = false;
      sessionStorage.removeItem('tempOrder');
      localStorage.removeItem('subdomain');
      if (_get(this.order, 'id')) {
        cookie.set('order_id', _get(this.order, 'id'), {
          path: '/',
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        });
      }
      return res;
    });
  }

  @action
  getOrder({ order_id = '' }) {
    this.loadingOrder = true;
    return request(`${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/${order_id}`, {
      method: 'GET',
    })
      .then(res => {
        this.order = _get(res, 'data.data', {});
        this.loadingOrder = false;
        return res;
      })
      .catch(err => {
        this.loadingOrder = false;
        console.error(err);
      });
  }

  @action
  getProductSuggestions({ order_id = '' }) {
    return request(
      `${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/${order_id}/product-suggestions`,
      {
        method: 'GET',
      }
    ).then(res => {
      this.productSuggestions = _get(res, 'data.data', []);
      return res;
    });
  }

  @action
  removeCouponCode({ order_id = '' }) {
    this.loading = true;
    return request(
      `${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/${order_id}/cancel-voucher`,
      {
        method: 'POST',
      }
    )
      .then(res => {
        if (_get(res, 'data.status')) {
          this.order = _get(res, 'data.data', {});
        }
        this.loading = false;
        return res;
      })
      .catch(err => {
        this.loading = false;
        return err;
      });
  }

  @action
  applyCouponCode({ order_id = '', voucher_code = '' }) {
    this.loading = true;
    return request(
      `${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/${order_id}/apply-voucher`,
      {
        method: 'POST',
        body: {
          voucher_code,
        },
      }
    )
      .then(res => {
        if (_get(res, 'data.status')) {
          this.order = _get(res, 'data.data', {});
        }
        this.loading = false;
        return res;
      })
      .catch(err => {
        this.loading = false;
        return err;
      });
  }

  @action
  postOrder({ order_id = '', order_items = '', shipping_address, customer_remarks = '' }) {
    this.loading = true;
    let params = {
      order_items,
    };
    if (shipping_address) {
      params.shipping_address = shipping_address;
    }
    if (customer_remarks) {
      params.customer_remarks = customer_remarks;
    }
    return request(`${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/${order_id}/submit`, {
      method: 'POST',
      body: params,
    })
      .then(res => {
        if (_get(res, 'data.status')) {
          this.order = _get(res, 'data.data', {});
        }
        this.loading = false;
        return res;
      })
      .catch(err => {
        this.loading = false;
        return err;
      });
  }

  @action
  getOrders({ page = 1, status = '' }) {
    this.loading = true;
    let apiUrl = `${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders?page=${page}`;
    if (status) {
      apiUrl += `&status=${status}`;
    }
    return request(apiUrl, {
      method: 'GET',
    })
      .then(res => {
        this.loading = false;
        this.orders = _get(res, 'data', {});
        return res;
      })
      .catch(err => {
        this.loading = false;
        console.error(err);
      });
  }

  @action
  putOrder({
    order_id = '',
    order_items = '',
    shipping_method = '',
    shipping_address = '',
    payment_method_id = '',
    custom_shipping_id = '',
  }) {
    this.loadingOrder = true;
    let params = {};
    if (order_items) {
      params = { ...params, order_items };
    }
    if (custom_shipping_id) {
      params = {
        ...params,
        custom_shipping_id,
      };
    }
    if (payment_method_id) {
      params = {
        ...params,
        payment_method_id,
      };
    }
    if (shipping_method) {
      params = {
        ...params,
        shipping_method,
      };
    }
    if (shipping_address) {
      params = {
        ...params,
        shipping_address,
      };
    }
    return request(`${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/${order_id}`, {
      method: 'PUT',
      body: params,
    })
      .then(res => {
        if (_get(res, 'data.status', false) === true) {
          this.order = _get(res, 'data.data', {});
        }
        this.loadingOrder = false;
        return res;
      })
      .catch(err => {
        this.loadingOrder = false;
        console.error(err);
      });
  }

  @action
  uploadPaymentSlip({ order_id, file, payment_reference_no }) {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('payment_reference_no', payment_reference_no);
    return request(
      `${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/${order_id}/payment-slip`,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then(res => {
        this.loading = false;
        return res;
      })
      .catch(err => {
        this.loading = false;
        console.error(err);
      });
  }

  @action
  getShipment({ seller_id }) {
    this.loading = true;
    let apiUrl = `${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/proceed-shipment/${seller_id}`;
    return request(apiUrl, {
      method: 'GET',
    })
      .then(res => {
        this.loading = false;
        this.order = _get(res, 'data.data', {});
        return res;
      })
      .catch(err => {
        this.loading = false;
        console.error(err);
      });
  }

  @action
  getOrderPreview({ order_id }) {
    this.loading = true;
    let apiUrl = `${process.env.NEXT_PUBLIC_API_SERVER}/customer/orders/${order_id}/preview`;
    return request(apiUrl, {
      method: 'GET',
    })
      .then(res => {
        this.loading = false;
        return res;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  @action
  getSubProducts({ product_detail_id }) {
    this.loading = true;
    let apiUrl = `${process.env.NEXT_PUBLIC_API_SERVER}/customer/product-details/${product_detail_id}/sub-products`;
    return request(apiUrl, {
      method: 'GET',
    })
      .then(res => {
        this.loading = false;
        return res;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  @action
  getCalculateBundlePrice({ product_detail_id, items = [] }) {
    this.loading = true;
    let apiUrl = `${process.env.NEXT_PUBLIC_API_SERVER}/customer/product-details/${product_detail_id}/calculate-bundle-price`;
    return request(apiUrl, {
      method: 'POST',
      body: {
        items,
      },
    })
      .then(res => {
        this.loading = false;
        return res;
      })
      .catch(() => {
        this.loading = false;
      });
  }
  @action
  resetOrder() {
    this.order = {};
  }
}

const orderStore = new OrderStore();
export default orderStore;
