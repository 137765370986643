import { action, observable } from 'mobx';
import request from '@utils/request';
import _get from 'lodash/get';
class WebStore {
  @observable loading = false;
  @observable webStore = {};

  @action
  getWebStore({ subdomain }) {
    this.loading = true;
    let apiUrl = `${process.env.NEXT_PUBLIC_API_SERVER}/store/${subdomain}`;
    return request(apiUrl, {
      method: 'GET',
    })
      .then(res => {
        this.loading = false;
        this.webStore = _get(res, 'data', {});
        return res;
      })
      .catch(err => {
        this.loading = false;
        console.error(err);
      });
  }

  @action
  resetWebStore() {
    this.webStore = {};
  }
}

const webStore = new WebStore();
export default webStore;
