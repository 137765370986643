import globalStore from './GlobalStore';
import orderStore from './OrderStore';
import accountStore from './AccountStore';
import webStore from './WebStore';
import { getInitStoreFactory } from '@utils/store';

class Store {
  constructor() {
    this.globalStore = globalStore;
    this.orderStore = orderStore;
    this.accountStore = accountStore;
    this.webStore = webStore;
  }
}

export const getInitStore = getInitStoreFactory(Store);
