import { observable, action } from 'mobx';
import request from '@utils/request';
import cookie from 'js-cookie';
import _get from 'lodash/get';
import * as Sentry from '@sentry/nextjs';
import { saveCustomerProfileToCookie } from '@utils/account';

class AccountStore {
  @observable loading = false;
  @observable me = {};

  async init() {
    const sol_cust = cookie.get('sol_cust');
    if (sol_cust) {
      this.me = JSON.parse(sol_cust);
      Sentry.setUser({
        email: _get(this.me, 'email'),
      });
    }
  }
  @action
  guestLogin({ pscode }) {
    this.loading = true;
    return request(
      `${process.env.NEXT_PUBLIC_API_SERVER}/customer/guest-login`,
      {
        method: 'POST',
        body: {
          pscode,
          canRedirectIf401: false,
        },
      },
      false
    )
      .then(res => {
        if (res && res.data && res.data.status) {
          saveCustomerProfileToCookie(_get(res, 'data.data'));
          const me = _get(res, 'data.data');
          this.me = me;
          this.loading = false;
        }
        this.loading = false;
        return res;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  @action
  login({
    pscode = '',
    asid = '',
    access_token = '',
    email = '',
    name = '',
    fb_profile_link = '',
  }) {
    this.loading = true;
    return request(
      `${process.env.NEXT_PUBLIC_API_SERVER}/customer/social-login`,
      {
        method: 'POST',
        body: {
          pscode,
          asid,
          access_token,
          name,
          email,
          fb_profile_link,
          canRedirectIf401: false,
        },
      },
      false
    )
      .then(res => {
        if (res && res.data && res.data.status) {
          saveCustomerProfileToCookie(_get(res, 'data.data'));
          const me = _get(res, 'data.data');
          this.me = me;
          this.loading = false;
        }
        this.loading = false;
        return res;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  @action
  loginWithFacebookCode({ pscode, code, redirect_uri, orderId }) {
    this.loading = true;
    let body = {
      pscode,
      fb_code: code,
      redirect_uri,
      canRedirectIf401: false,
    };
    if (orderId) {
      body['orderId'] = orderId;
    }
    return request(`${process.env.NEXT_PUBLIC_API_SERVER}/customer/fb-login`, {
      method: 'POST',
      body,
    })
      .then(res => {
        if (res && res.data && res.data.status) {
          saveCustomerProfileToCookie(_get(res, 'data.data'));
          const me = _get(res, 'data.data');
          this.me = me;
          this.loading = false;
        }
        this.loading = false;
        return res;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  @action
  logout() {
    this.me = {};
    cookie.remove('sol_cust', {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    });
  }
}

const accountStore = new AccountStore();
export default accountStore;
