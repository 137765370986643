// let host = null;

// fallback language
export const fallbackLng = 'en-US';

// is dev env
export const isDev = process.env.NODE_ENV !== 'production';

// is server side
export const isServer = typeof window === 'undefined';

// asset prefix
export const assetPrefix = isDev ? '' : '';

export const localSubdomain = 'coldbrew';

export const state = [
  {
    label: 'Malaysia',
    id: 'malaysia',
    states: [
      {
        label: 'Wilayah Persekutuan',
        WM: true,
        id: 'wilayah_persekutuan',
      },
      {
        label: 'Selangor',
        WM: true,
        id: 'selangor',
      },
      {
        label: 'Pahang',
        WM: true,
        id: 'pahang',
      },
      {
        label: 'Terengganu',
        WM: true,
        id: 'terengganu',
      },
      {
        label: 'Pulau Pinang',
        WM: true,
        id: 'pulau_pinang',
      },
      {
        label: 'Perak',
        WM: true,
        id: 'perak',
      },
      {
        label: 'Johor',
        WM: true,
        id: 'johor',
      },
      {
        label: 'Negeri Sembilan',
        WM: true,
        id: 'negeri_sembilan',
      },
      {
        label: 'Melaka',
        WM: true,
        id: 'melaka',
      },
      {
        label: 'Perlis',
        WM: true,
        id: 'perlis',
      },
      {
        label: 'Kedah',
        WM: true,
        id: 'kedah',
      },
      {
        label: 'Kelantan',
        WM: true,
        id: 'kelantan',
      },
      {
        label: 'Sarawak',
        WM: false,
        id: 'sarawak',
      },
      {
        label: 'Sabah',
        WM: false,
        id: 'sabah',
      },
      {
        label: 'Labuan',
        WM: false,
        id: 'labuan',
      },
    ],
  },
  {
    label: 'Singapore',
    id: 'singapore',
    states: [
      {
        label: 'Singapore',
        id: 'singapore',
      },
    ],
  },
  {
    label: 'China',
    id: 'china',
    states: [
      {
        label: 'Hong Kong SAR',
        id: 'hong-kong',
      },
    ],
  },
  {
    label: 'Indonesia',
    id: 'indonesia',
    states: [
      {
        label: 'Indonesia',
        id: 'indonesia',
      },
    ],
  },
];

// update host for server side
// export default ctx => {
//   if (ctx) {
//     const { req = {} } = ctx;
//     if (req.extraData && req.extraData.host) {
//       host = req.extraData.host;
//     } else {
//       const { headers = {} } = req;
//       host = headers['host'];
//     }
//   }
// };
