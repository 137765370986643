import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Head from 'next/head';
import Router from 'next/router';

const styles = theme => ({
  '@global': {
    '.container': {
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
      width: '100%',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: 700,
        margin: '0 auto',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 960,
        margin: '0 auto',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1200,
        margin: '0 auto',
      },
    },
    b: {
      fontFamily: [
        '"CircularStd-Bold"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    '.text-bold': {
      fontFamily: [
        '"CircularStd-Bold"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    strong: {
      fontFamily: [
        '"CircularStd-Bold"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    a: {
      textDecoration: 'unset',
    },
    '.text-highlight': {
      color: theme.palette.highlight.main,
    },
  },
});

/**
 * global route listener, scroll to top when page change
 */
Router.events.on('routeChangeComplete', url => {
  if (url === '/store' || url.startsWith('/p/')) {
    return url;
  }
  return window.scrollTo(0, 0);
});

const Layout = ({ classes, children }) => {
  return (
    <div>
      <Head>
        <title>SellOnLive</title>
      </Head>
      <div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(Layout);
